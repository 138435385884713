<template>
  <div class="text-dark-blue">
    <Menu />
    <Payments />
  </div>
</template>

<script>
import Menu from "@/components/start/base/Menu.vue";
import Payments from "../components/start/Payments.vue";

export default {
  components: {
    Menu,
    Payments,
  },
};
</script>

<style lang="scss">
</style>