<template>
  <div v-if="isLoading" class="text-center">
    <b-spinner></b-spinner>
  </div>
  <div v-else class="start-page">
    <div class="position-absolute" style="left: 10px; top: 10px">
      <router-link
        to="/start"
        class="d-flex align-items-center router-hover justify-content-center mt-3"
      >
        <font-awesome-icon class="text-blue mr-2" :icon="faArrowLeft" />
        <p class="text-blue">{{ $t("back") }}</p>
      </router-link>
    </div>
    <h2>{{ $t("paymentTitle") }}</h2>
    <p class="text-muted">
      {{ $t("toPayDescription") }}
    </p>
    <div class="alert alert-info" v-html="$t('toPayDescription2')"></div>
    <div class="w-100 mt-4">
      <div class="row justify-content-center">
        <div
          class="col-12 col-md-4 col-lg-4 my-2"
          v-for="(item, i) in taxYearList"
          :key="i"
        >
          <div class="text-dark-blue">
            <div class="card-2 text-center">
              <div
                v-html="$t('taxYearListTitle', { year: item.taxYear })"
              ></div>
              <p class="text-muted mt-3">
                {{
                  $t("applyTaxDescription", {
                    date: new Date(item.completedDate).toLocaleString(
                      $i18n.locale
                    ),
                  })
                }}
              </p>
              <div class="mt-3">
                <button v-if="item.isPayment" class="blue-button bg-success">
                  <FontAwesomeIcon class="check-icon" :icon="faCheckCircle" />
                  {{ $t("paySucceed") }}
                </button>
                <button
                  v-else-if="item.status === 6"
                  class="blue-button"
                  @click="$router.push(`/pay/${item.userId}/${item.taxYear}`)"
                >
                  {{ $t("payIt") }}
                </button>
                <button v-else disabled class="blue-button disabled">
                  {{ $t("taxWaiting") }}
                </button>
              </div>
              <div
                class="mt-4 w-100 alert alert-warning"
                v-if="item.status !== 6"
              >
                {{ $t("taxWaitingDescription") }}
              </div>
              <div class="mt-4 w-100 alert alert-success" v-else>
                {{ $t("taxDoneDescription") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFile,
  faCreditCard,
  faCircleLeft,
  faCheckCircle,
  faClipboard,
} from "@fortawesome/free-regular-svg-icons";
import { mapGetters } from "vuex";
import { getPaymentsOfUser } from "../../services/modules/User";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faFile,
      faArrowLeft,
      faCircleLeft,
      faCheckCircle,
      faCreditCard,
      faClipboard,
      isLoading: true,
      taxYearList: [],
    };
  },
  computed: {
    ...mapGetters(["activeUser"]),
  },
  methods: {
    async GET_PAYMENT_OF_USER() {
      this.isLoading = true;
      console.log(this.activeUser);
      const response = await getPaymentsOfUser(this.activeUser.id);
      if (response.message === "OK") {
        this.taxYearList = response.data;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.GET_PAYMENT_OF_USER();
  },
};
</script>

<style lang="scss">
.start-page {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/image-3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  a {
    &:hover {
      color: #161640;
    }
  }

  .card-2 {
    padding: 30px 50px;
    background-color: #f1f8fa;
    border-radius: 10px;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 12px rgba(6, 11, 47, 0.06);
    .check-icon {
      font-size: 16px !important;
    }
    .bg-success {
      background-color: #14cfa070 !important;
    }
    h4 {
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: 767px) {
  .start-page {
    display: block;
    background-image: none;
    min-height: calc(100vh);
    padding-top: 80px;
  }
  .start-page-mobile {
    margin-top: 500px !important;
    text-align: center;
    font-size: 26px;
  }

  .start-page {
    .card {
      margin: 0px 20px;
    }
  }
}
</style>
